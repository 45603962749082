import * as React from "react"
import "./thank-you.scss"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Button } from "react-bootstrap"
import { Link, navigate } from "gatsby"
import AzulManagementLogo from "../images/logos/azulManagementLogo"
import BlueMarkLogo from "../images/logos/blueMarkLogo"
import TrueBlueLogo from "../images/logos/trueBlueLogo"
import RoyalPalmLogo from "../images/logos/royalPalmLogo"
import { Group } from '@mantine/core'


const ThankYou = () => {
	return (
    
  <Layout>
    <Seo title="Thank You" />
    <div className="thank-you">
        <div className="text-box">
            < div className="header">
            <h1>Thank You.</h1>
            <p>Thank you for your inquiry, our team will send you a response in the next 24 hours.</p>
            </div>
            
           
 
            <div className="button-group">
                
                <Link to="/" id="first">
                    <Button id="button-three" onClick={() => navigate(-1)}>
                        Back
                    </Button>
                </Link>
                <Link to="/realestate">
                    <Button id="button-four">
                        View Listings
                    </Button>
                </Link>
            </div>

            <div className="logos">
                <Group spacing="lg">
                <RoyalPalmLogo />
                    <BlueMarkLogo />
                    <AzulManagementLogo />
                    <RoyalPalmLogo />
                    <TrueBlueLogo />
                </Group>
            </div>

        </div>
    </div>
  </Layout>
 );
}
export default ThankYou;